import * as React from 'react';
import NavMenu from './navigationComponents/NavMenu';
import SideNavMenu from './navigationComponents/SideNavSlideMenu';
import * as AuthenticatedRouteStore from '../store/AuthenticatedRoute';
import { connect } from 'react-redux';
import { IApplicationState } from '../store/index';
import { withRouter } from 'react-router-dom';
import * as ProfileStore from '../store/Profile';
import * as TicketsStore from '../store/Tickets';
import * as CustomerStore from '../store/Customer';
import * as NotificationStore from '../store/Notification';
import Logger from '../Utils/Logger';
import ToastBar from './ToastBarComponents/ToastBar';
import BrowserModal from './CommonComponents/BrowserModal';
import KPMGFooter from './CommonComponents/KPMGFooter';
import BannerService from '../apiServices/BannerService';
import ScrollToTop from './navigationComponents/smallComponents/ScrollToTop';
import i18n from './translationcomponent/i18n';
import { Container, Alert } from 'react-bootstrap';
import ErrorBoundary from './HOC/ErrorBoundary';
import { IBanner } from '../Interface/Interfaces';

type LayoutProps =
    AuthenticatedRouteStore.IAuthenticatedRouteState
    & typeof AuthenticatedRouteStore.actionCreators
    & ProfileStore.IProfileState
    & typeof ProfileStore.actionCreators
    & TicketsStore.ITicketsState
    & typeof TicketsStore.actionCreators
    & typeof CustomerStore.actionCreators
    & typeof NotificationStore.actionCreators;

export interface ILayoutState {
    isSideNavOpen: boolean;
    deployInfo: IBanner;
    languageChanged: boolean;
    isWarningClosed: boolean;
}

class Layout extends React.Component<LayoutProps, ILayoutState> {
    static displayName = 'Layout';

    constructor(props: any) {
        super(props)
        this.state = {
            isSideNavOpen: false,
            deployInfo: {
                id: '',
                bannerType: 0,
                textSwedish: '',
                textEnglish: '',
                startDate: '',
                endDate: ''
            },
            languageChanged: false,
            isWarningClosed: false
        }
    }

    public componentDidMount() {
        Logger.log(this.props.loggedIn);
    }

    public componentDidUpdate(prevProps: LayoutProps) {
        if (this.props.loggedIn && (prevProps.loggedIn !== this.props.loggedIn)) {
            BannerService.GetActiveBanner().then(result => {
                this.setState({ deployInfo: result });
            })
            this.props.connectTicket();
        }
        if (this.state.languageChanged && this.props.loggedIn) {
            this.setState({ languageChanged: false }, () => {
                BannerService.GetActiveBanner().then(result => {
                    this.setState({ deployInfo: result });
                })
            })
        }
        i18n.on('languageChanged', (lng) => {
            this.setState({ languageChanged: true });
        })
        if (window.location.pathname === '/'
            || window.location.toString().indexOf("/home") > -1
            || window.location.toString().indexOf("/activate") > -1
            || window.location.pathname === '/unauthorized'
            || window.location.pathname === '/notifications'
            || window.location.pathname === '/tickets/new'
            || window.location.pathname === '/tickets'
            || window.location.toString().indexOf("/customers") > -1
            || window.location.toString().indexOf("/tickets") > -1

        ) {
            document.getElementById('container')?.classList.remove('old', 'grid-container');
        } else {
            document.getElementById('container')?.classList.add('old', 'grid-container');
        }
    }

    public toggleSideNav = () => {
        this.setState({ isSideNavOpen: !this.state.isSideNavOpen });
    }

    public closeSideNav = () => {
        this.toggleSideNav();
    }

    public render() {
        return (
            <ErrorBoundary>
                {this.props.loggedIn &&
                    <>
                        <SideNavMenu
                            toggleSideNav={this.toggleSideNav}
                            isOpen={this.state.isSideNavOpen}
                            firstName={this.props!.me.firstName!}
                            lastName={this.props!.me.lastName!}
                            FirstName={this.props!.FirstName}
                            LastName={this.props!.LastName}
                            logOut={this.props.logOut}
                            getNotifications={this.props.getNotifications}
                            ticketsNotifications={this.props.ticketsNotifications}
                            clearTicketNotifications={this.props.clearTicketNotifications}
                        />
                        <NavMenu
                            toggleSideNav={this.toggleSideNav}
                            getNotifications={this.props.getNotifications}
                            ticketsNotifications={this.props.ticketsNotifications}
                            clearTicketNotifications={this.props.clearTicketNotifications}
                        />
                        <ToastBar />
                        {(!this.state.deployInfo.id.isEmptyOrWhiteSpace() && !this.state.isWarningClosed) &&
                            <>
                                <ScrollToTop />
                                <Alert variant="warning" className="text-center" dismissible onClose={() => this.setState({ isWarningClosed: true })}>
                                    {i18n.language === "en-US" &&
                                        this.state.deployInfo.textEnglish
                                    }
                                    {i18n.language !== "en-US" &&
                                        this.state.deployInfo.textSwedish
                                    }
                                </Alert>
                            </>
                        }
                    </>
                }
                <Container id="container" fluid className="content">
                    {this.props.children}
                </Container>
                {this.props.loggedIn &&
                    <KPMGFooter />
                }
                <BrowserModal />
            </ErrorBoundary>
        );
    }
}
export default withRouter(connect(
    (state: IApplicationState) => ({ ...state.profile, ...state.authenticatedRoute, ...state.tickets }), ({
        ...ProfileStore.actionCreators,
        ...AuthenticatedRouteStore.actionCreators,
        ...TicketsStore.actionCreators,
        ...CustomerStore.actionCreators,
        ...NotificationStore.actionCreators
    }),
)(Layout) as any);
