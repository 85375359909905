import * as React from 'react';
import { Translation } from 'react-i18next';
import { TicketStatus } from '../../Interface/Enum';
import AppInsightsUtils from '../../Utils/AppInsightsUtils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLockAlt } from '@fortawesome/pro-solid-svg-icons';
import { faSuperpowers } from '@fortawesome/free-brands-svg-icons';
import DateTimeHelper from '../../Utils/DateTimeHelper';
import { history } from '../../store/configureStore';

export interface ITicketCardProps {
    Title: string;
    Customer: any;
    LastEventTime: string;
    Status: number;
    Confidential: boolean;
    SignalRPushed: boolean;
    TicketType: string;
    Id: string;
    Pop: boolean;
    RefNumber: string;
}

export default class TicketCard extends React.Component<ITicketCardProps, {}> {
    static displayName = 'TicketCard';

    public componentDidMount() {
        if (this.props.Pop) {
            setTimeout(() => {
                setTimeout(() => { document.querySelectorAll('.my-pop')[0]?.classList.add('ticket-notify'); }, 500)
                if (document.querySelectorAll('.ticket-notify')) {
                    var nodes = document.querySelectorAll('.ticket-notify');
                    nodes.forEach((node) => {
                        node.classList.remove('ticket-notify');
                    })
                }
            }, 3000);
        }
    }

    public componentDidUpdate(prevProps: ITicketCardProps) {
        if (this.props !== prevProps) {
            if (this.props.Pop) {
                setTimeout(() => { document.querySelectorAll('.my-pop')[0]?.classList.add('ticket-notify'); }, 500)
                setTimeout(() => {
                    if (document.querySelectorAll('.ticket-notify')) {
                        var nodes = document.querySelectorAll('.ticket-notify');
                        nodes.forEach((node) => {
                            node.classList.remove('ticket-notify');
                        })
                    }
                }, 3000);
            }
        }
    }

    public openTicket = () => {
        AppInsightsUtils.TrackEvent("Homepage ticket clicked", { message: "ticket clicked" })
        if (this.props.Status === TicketStatus.Draft) {
            history.push({ pathname: '/tickets/new', state: { "ticketDraftId": this.props.Id } });
        } else {
            history.push(`/tickets/${this.props.RefNumber}`);
        }
    }

    public render() {
        return (
            <Translation>{
                (t) => {
                    return (
                        <div className="grid-x" style={{ cursor: 'pointer' }} onClick={this.openTicket}>
                            <div className={this.props.Pop ? "cell medium-12 text-small shadow radius padding-1 margin-bottom-1 my-pop" : "cell medium-12 text-small shadow radius padding-1 margin-bottom-1"}> {/*Here we wanna add the class ticket-notify for makinging it pop more*/}
                                <div className="grid-x">
                                    <div className="cell">
                                        <div className="ticket-state positioning-top positioning-right line-height-1 text-right rounded">
                                            <span>&nbsp;</span>
                                            {this.props.SignalRPushed &&
                                                <span className="text-color-kpmg-green" title={t('tickets:Status.NewUpdate')}>
                                                    <FontAwesomeIcon icon={faSuperpowers} />
                                                </span>
                                            }
                                            {this.props.Confidential &&
                                                <span className="text-color-alert" title={t('tickets:Status.Confidential')}>
                                                    <FontAwesomeIcon icon={faLockAlt} />
                                                </span>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <h3 className="h6 text-body-font text-color-black font-bold">{this.props.Title}</h3>
                                <div className="grid-x">
                                    <div className="cell small-3 text-right text-color-dark-gray">
                                        {t('tickets:Resolved.Customer')}
                                    </div>
                                    <div className="cell small-8 small-offset-1">
                                        {this.props.Customer.name}
                                    </div>
                                </div>
                                <div className="grid-x">
                                    <div className="cell small-3 text-right text-color-dark-gray">
                                        {t('tickets:Resolved.TicketType')}
                                    </div>
                                    <div className="cell small-8 small-offset-1">
                                        {this.props.TicketType}
                                    </div>
                                </div>
                                <div className="grid-x">
                                    <div className="cell small-3 text-right text-color-dark-gray">
                                        {t('tickets:Ticket.LatestEvent')}
                                    </div>
                                    <div className="cell small-8 small-offset-1">
                                        {DateTimeHelper.FormatYearMonthDayWithTime(this.props.LastEventTime)}
                                    </div>
                                </div>
                                <div className="grid-x">
                                    <div className="cell small-3 text-right text-color-dark-gray">
                                        {t('tickets:Search.Status')}
                                    </div>
                                    <div className="cell small-8 small-offset-1">
                                        {t('tickets:Status.' + TicketStatus[this.props.Status])}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }
            }
            </Translation>
        );

    }
}
