import * as React from 'react';
import { Translation } from 'react-i18next';
import AppInsightsUtils from '../../Utils/AppInsightsUtils';
import { changePageTitle } from '../../Utils/CommonUtils';
import { ICustomerSelectOption, ICustomer } from '../../Interface/Interfaces';
import { TicketStatus, TypeOfTicket } from '../../Interface/Enum';
import { NavLink, RouteComponentProps } from 'react-router-dom';
import * as AuthenticatedRouteStore from '../../store/AuthenticatedRoute';
import * as TicketsStore from '../../store/Tickets';
import * as CustomerStore from '../../store/Customer';
import { IApplicationState } from '../../store';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ErrorBoundary from '../HOC/ErrorBoundary';
import { PageTransition, Cardwrapper } from '@kpmgportals/genericcomponents'
import { Col, Row, Button, Form } from 'react-bootstrap';
import {
    GenericReactSelect,
    IGenericOption
} from '@kpmgportals/reactselect';
import UserOptionOrgReactSelect from '../../components/CommonComponents/Inputs/UserOptionOrgReactSelect';
import { OrgSingleValue, OrgOption, createOrgSelectOptions } from '../../Utils/ReactSelectHelper';
import { faTimes, faPlusCircle, faThLarge, faList } from '@fortawesome/pro-solid-svg-icons';
import { debounce } from 'ts-debounce';
import { handleKeyPress } from '../../Utils/CommonUtils';
import i18n from 'i18next';
import { bindActionCreators } from 'redux';
import TicketList from "./TicketListing/List/TicketList";
import TicketCards from "./TicketListing/Cards/TicketCards";

const ListingMinWidth = 1400;
const ListingMinHeight = 700;
const TicketsLayout = 'ticketsLayout';
const ListingGrid = 'grid';
const ListingList = 'list';

type ITicketsProps = {
    states: {
        tickets: TicketsStore.ITicketsState,
        customer: CustomerStore.ICustomerState,
        authenticatedRoute: AuthenticatedRouteStore.IAuthenticatedRouteState
    }
    actions: {
        tickets: typeof TicketsStore.actionCreators,
        customer: typeof CustomerStore.actionCreators,
    }
};

type ITicketsCombinedProps = ITicketsProps & RouteComponentProps;

interface ITicketListState {
    ticketsLayoutList: boolean;
    organizationDropdownOptions: ICustomerSelectOption[]
    selectedCustomer: ICustomerSelectOption | null;
    showResolved: boolean;
    showConfidential: boolean;
    tickeStatus: IGenericOption | null;
    ticketType: IGenericOption | null;
    search: string;
}

class Tickets extends React.Component<ITicketsCombinedProps, ITicketListState> {
    static displayName = 'Tickets';

    private dropDown: HTMLSelectElement;
    private showResolvedCheckBox: HTMLInputElement;
    private showConfidentialCheckBox: HTMLInputElement;
    private searchInput: HTMLInputElement;
    private selectedCustomerId: string = "";

    private userScreenHeight = window.screen.height;
    private userScreenWidth = window.screen.width;

    constructor(props: ITicketsCombinedProps) {
        super(props);
        this.state = {
            ticketsLayoutList: (this.userScreenWidth < ListingMinWidth || this.userScreenHeight < ListingMinHeight) ? false :
                (localStorage.getItem(TicketsLayout) === ListingGrid) ? false : true,
            organizationDropdownOptions: [],
            selectedCustomer: null,
            showResolved: false,
            showConfidential: false,
            search: "",
            tickeStatus: null,
            ticketType: null
        };
    }

    public componentDidMount() {
        const locationCustomer: ICustomer = (this.props.location.state !== undefined && this.props.location.state !== null) ? (this.props.location.state! as any).customer : null;
        const setCustomerFilter: boolean = (this.props.location.state !== undefined && this.props.location.state !== null) ? (this.props.location.state! as any).setCustomerFilter : false;
        if (locationCustomer && locationCustomer?.id !== this.state.selectedCustomer?.id && setCustomerFilter) {
            let customerId = locationCustomer.id;
            this.setState({
                selectedCustomer: {
                    ...locationCustomer,
                    label: locationCustomer.name,
                    value: locationCustomer
                }
            });
            this.props.actions.tickets.getAllCustomersTickets("", undefined, false, customerId);
            this.props.actions.tickets.getAllCustomersResolvedTickets(customerId);

            this.props.actions.customer.getMyDropDownCustomer(customerId)
        } else {
            this.props.actions.tickets.getAllCustomersTickets();
            this.props.actions.tickets.getAllCustomersResolvedTickets();
        }

        this.handleScroll = this.handleScroll.bind(this);
        this.props.actions.tickets.clearTicketNotifications();

        window.addEventListener('scroll', this.handleScroll, true);
    }

    public componentWillUnmount() {
        this.props.actions.tickets.leftComponentTickets();
        window.removeEventListener('scroll', this.handleScroll, true);
    }

    public componentDidUpdate(prevProps: ITicketsCombinedProps) {

        if (this.props.states.authenticatedRoute.me !== prevProps.states.authenticatedRoute.me) {
            this.props.actions.customer.getMyDropDownCustomers();
        }
        if (this.state.organizationDropdownOptions.length === 0 && this.props.states.customer.myDropDownCustomers.length > 0) {
            this.setOrganizationSelectOptions();
        }
    }

    public setOrganizationSelectOptions = () => {
        let organisations = createOrgSelectOptions(this.props.states.customer.myDropDownCustomers);
        if (!organisations || organisations.length === 0) {
            return;
        }

        this.setState({
            organizationDropdownOptions: organisations
        });
    }

    public handleScroll = (e: any) => {
        if (this.props.states.tickets.tickets.hasNextPage && !this.props.states.tickets.tickets.loading && (window.innerHeight + document.documentElement!.scrollTop >= (document.documentElement!.offsetHeight * 0.75))) {
            this.props.actions.tickets.getAllCustomersTickets(this.searchInput.value, Number(this.dropDown.value!), this.showResolvedCheckBox.checked, this.selectedCustomerId, this.showConfidentialCheckBox.checked, true);
        }
    }

    public handleSearch = (searchText: string) => {
        this.setState({ search: searchText }, () => {
            this.debouncedSearch();
        });
    }

    public getMoreCustomerTickets = (gettingMore: boolean = false) => {
        this.loadCustomerTickets(true);
    }

    public setOrganization = async (selectedCustomer: ICustomerSelectOption | null) => {
        this.setState({ selectedCustomer: selectedCustomer }, () => {
            this.getAllCustomerTickets();
            if (selectedCustomer !== null) {
                this.props.actions.customer.getMyDropDownCustomer(selectedCustomer.value.id);
                return;
            }

            this.props.actions.customer.clearMyDropDownCustomer();
        });
    }

    private getAllCustomerTickets = () => {
        this.loadCustomerTickets(false);
    }

    private debouncedSearch = debounce(this.getAllCustomerTickets, 300);

    private loadCustomerTickets = (gettingMore: boolean = false) => {

        this.props.actions.tickets.getAllCustomersTickets(
            this.state.search,
            this.state.tickeStatus !== null ? this.state.tickeStatus.value as number : 0,
            this.state.showResolved,
            this.state.selectedCustomer?.id ?? "",
            this.state.showConfidential,
            gettingMore,
            this.state.ticketType !== null ? this.state.ticketType.value as number : 0);

        AppInsightsUtils.TrackEvent("Ticket searched", { SearchValue: this.state.search });
        if (this.state.showResolved) {
            AppInsightsUtils.TrackEvent("Tickets show only resolved", {});
        }
        if (this.state.showConfidential) {
            AppInsightsUtils.TrackEvent("Tickets show only confidential", {});
        }
    }

    get allTicketStatus(): IGenericOption[] {
        return [
            { value: TicketStatus.Created, label: i18n.t('tickets:Status.Select'), data: undefined },
            { value: TicketStatus.WaitingForKPMG, label: i18n.t('tickets:Status.WaitingForKPMG'), data: undefined },
            { value: TicketStatus.WaitingForPartner, label: i18n.t('tickets:Status.WaitingForPartner'), data: undefined }
        ];
    }

    get allTicketTypes(): IGenericOption[] {
        return [
            { value: TypeOfTicket.PartnerTicket, label: i18n.t('tickets:TicketTypes.PartnerTicket'), data: undefined },
            { value: TypeOfTicket.PreparedByPartner, label: i18n.t('tickets:TicketTypes.PreparedByPartner'), data: undefined }
        ];
    }

    private setTicketStatus = (option: IGenericOption | null) => {
        this.setState({ tickeStatus: option }, () => {
            this.getAllCustomerTickets();
        });
    }

    private setTicketType = (option: IGenericOption | null) => {
        this.setState({ ticketType: option }, () => {
            this.getAllCustomerTickets();
        });
    }

    private setShowConfidential = (e: React.FormEvent<HTMLInputElement>) => {
        let value = false;
        if (e !== null) {
            value = e.currentTarget.checked;
        }

        this.setState({ showConfidential: value }, () => {
            this.getAllCustomerTickets();
        });
    }

    private setShowResolved = (e: React.FormEvent<HTMLInputElement>) => {
        let value = false;
        if (e !== null) {
            value = e.currentTarget.checked;
        }

        this.setState({ showResolved: value }, () => {
            this.getAllCustomerTickets();
        });
    }

    public render() {
        return (
            <ErrorBoundary>
                <PageTransition>
                    <Translation>
                        {
                            (t) => {
                                changePageTitle('PageTitle.Tickets');
                                return (
                                    <>
                                        <Row className="mt-3">
                                            <Col>
                                                <Row className="justify-content-between align-items-center mb-3">
                                                    <Col xs={12} lg="auto">
                                                        <h1 className="page-title">{t('tickets:MyTickets')}</h1>
                                                    </Col>
                                                    <Col xs={12} lg="auto">
                                                        <NavLink exact to={{ pathname: "/tickets/new", state: { customer: this.state.selectedCustomer?.value } }}>
                                                            <Button
                                                                variant="primary"

                                                                className="mb-3 mb-lg-0"
                                                            >
                                                                {t('tickets:Create')}
                                                                <FontAwesomeIcon
                                                                    icon={faPlusCircle}
                                                                    size="lg"
                                                                    className="ml-3"
                                                                />
                                                            </Button>
                                                        </NavLink>
                                                    </Col>
                                                </Row>
                                                <Row className="grid-margin-bottom">
                                                    <Col>
                                                        <Cardwrapper>
                                                            <Row>
                                                                <Col>
                                                                    <h2>{t('tickets:Search.SearchTitle')}</h2>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col xs={12} lg>
                                                                    <Form.Group
                                                                        controlId="tickets.search.text"
                                                                        className="position-relative"
                                                                    >
                                                                        <Form.Label>{t('tickets:Search.Search')}</Form.Label>
                                                                        <Form.Control
                                                                            onChange={e => {
                                                                                this.handleSearch(e.currentTarget.value);
                                                                            }}
                                                                            onKeyPress={(e: React.FormEvent<HTMLInputElement>) =>
                                                                                handleKeyPress(e, this.getAllCustomerTickets, e.currentTarget.value)
                                                                            }
                                                                            as="input"
                                                                            value={this.state.search}
                                                                            type="text"
                                                                            placeholder={t('tickets:Search.PlaceHolder')}
                                                                        />
                                                                        <Button
                                                                            value="delete-text-button"
                                                                            onClick={() => { this.handleSearch(""); }}
                                                                            title="Clear search field"
                                                                            variant="link"
                                                                            className={`text-black position-absolute position-bottom position-right input-clear${(this.state.search === "" ? "" : " is-visible")}`}
                                                                        >
                                                                            <FontAwesomeIcon icon={faTimes} />
                                                                        </Button>
                                                                    </Form.Group>
                                                                </Col>
                                                                <>
                                                                    <Col xs={12} lg>
                                                                        <Form.Group controlId="tickets.search.status">
                                                                            <Form.Label>{t('tickets:Search.Status')}</Form.Label>
                                                                            <GenericReactSelect
                                                                                isClearable
                                                                                inputId="tickets.search.status"
                                                                                options={this.allTicketStatus}
                                                                                onChange={(e: any) => this.setTicketStatus(e)}
                                                                                placeholder={t('tickets:Status.Select')}
                                                                                value={this.state.tickeStatus}
                                                                                isDisabled={this.state.showResolved ? true : false}
                                                                            />
                                                                        </Form.Group>
                                                                    </Col>
                                                                    <Col xs={12} lg>
                                                                        <Form.Group controlId="ticket-search-organization-dropdown">
                                                                            <Form.Label>{t('tickets:Organization')}</Form.Label>
                                                                            <UserOptionOrgReactSelect
                                                                                id="ticket-search-organization-dropdown"
                                                                                isMulti={false}
                                                                                singleValue={OrgSingleValue}
                                                                                option={OrgOption}
                                                                                defaultValue={this.props.states.customer.myDropDownCustomer.name !== ''
                                                                                    ? { value: this.props.states.customer.myDropDownCustomer, label: this.props.states.customer.myDropDownCustomer.name }
                                                                                    : { value: 'All', label: t('tickets:AllOrganizations') }}
                                                                                options={createOrgSelectOptions(this.props.states.customer.myDropDownCustomers, t('tickets:AllOrganizations'))}
                                                                                placeholder={t('tickets:OrgDropdownSelectPlaceholder')}
                                                                            />
                                                                        </Form.Group>
                                                                    </Col>
                                                                    <Col xs={12} lg>
                                                                        <Form.Group controlId="tickets.search.ticketype">
                                                                            <Form.Label>{t('tickets:Search.TicketTypes')}</Form.Label>
                                                                            <GenericReactSelect
                                                                                isClearable
                                                                                inputId="tickets.search.ticketype"
                                                                                options={this.allTicketTypes}
                                                                                onChange={(e: any) => this.setTicketType(e)}
                                                                                placeholder={t('common:Action.All')}
                                                                                value={this.state.ticketType}
                                                                            />
                                                                        </Form.Group>
                                                                    </Col>
                                                                </>
                                                            </Row>
                                                            <Row>
                                                                <Col xs="auto" className="mb-3 mb-md-0">
                                                                    <Form.Check
                                                                        custom
                                                                        checked={this.state.showResolved}
                                                                        type="checkbox"
                                                                        id="showResolved"
                                                                        label={t('tickets:ShowResolved')}
                                                                        onChange={this.setShowResolved}
                                                                    />
                                                                </Col>
                                                                <Col xs="auto">
                                                                    <Form.Check
                                                                        custom
                                                                        checked={this.state.showConfidential}
                                                                        type="checkbox"
                                                                        id="showConfidentual"
                                                                        label={t('tickets:ShowConfidential')}
                                                                        onClick={this.setShowConfidential}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                        </Cardwrapper>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                        {this.userScreenHeight >= ListingMinHeight && this.userScreenWidth >= ListingMinWidth &&
                                            <Row className="justify-content-end mb-3">
                                                <Col xs="auto">
                                                    <Row>
                                                        <Col>
                                                            {t('tickets:View')}
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <Button
                                                                variant="outline-primary"
                                                                size="sm"
                                                                className="mr-1"
                                                                onClick={
                                                                    () => {
                                                                        this.setState({ ticketsLayoutList: true });
                                                                        localStorage.setItem(TicketsLayout, ListingList);
                                                                    }
                                                                }
                                                            >
                                                                <FontAwesomeIcon
                                                                    icon={faList}
                                                                    size="lg"
                                                                />
                                                            </Button>
                                                            <Button
                                                                variant="outline-primary"
                                                                size="sm"
                                                                onClick={
                                                                    () => {
                                                                        this.setState({ ticketsLayoutList: false });
                                                                        localStorage.setItem(TicketsLayout, ListingGrid);
                                                                    }
                                                                }
                                                            >
                                                                <FontAwesomeIcon
                                                                    icon={faThLarge}
                                                                    size="lg"
                                                                />
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        }
                                        <Row>
                                            <Col>
                                                {(this.props.states.tickets.tickets.results.length === 0 && !this.props.states.tickets.tickets.loading) &&
                                                    <Row>
                                                        <Col className="text-center">
                                                            <h2>{t('tickets:NoTickets')}</h2>
                                                            <Button
                                                                as={NavLink}
                                                                to="/tickets/new"
                                                            >
                                                                {t('tickets:Create')}<FontAwesomeIcon icon={faPlusCircle} size="lg" className="ml-3" />
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                }
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                {this.state.ticketsLayoutList ? (
                                                    <TicketList
                                                        tickets={this.props.states.tickets.tickets.results}
                                                        isLoading={this.props.states.tickets.tickets.loading ? true : false}
                                                    />
                                                ) :
                                                    (
                                                        <TicketCards
                                                            tickets={this.props.states.tickets.tickets.results}
                                                            isLoading={this.props.states.tickets.tickets.loading ? true : false}
                                                        />
                                                    )
                                                }
                                            </Col>
                                        </Row>
                                    </>
                                );
                            }
                        }
                    </Translation>
                </PageTransition>
            </ErrorBoundary>
        );
    }
}

export default connect(
    (state: IApplicationState) => ({
        states: {
            tickets: state.tickets,
            customer: state.customer,
            authenticatedRoute: state.authenticatedRoute,
        }
    }),
    (dispatch) => ({
        actions: {
            tickets: bindActionCreators(TicketsStore.actionCreators, dispatch),
            customer: bindActionCreators(CustomerStore.actionCreators, dispatch)
        }
    })
)(Tickets);
